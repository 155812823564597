export default `
  query getCmsPageQuery($identifier: String) {
    cmsPage(identifier: $identifier) {
      identifier
      url_key
      title
      content
      content_heading
      page_layout
      meta_title
      meta_description
      meta_keywords
      custom_form_id
      custom_form_position
    }
  }
`;
