import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import { Logger } from '~/helpers/logger';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.error("Placeorder api to Magento (error)")
    Logger.warn("Placeorder api to Magento (warn)")
    const { data } = await context.app.$vsf.$magento.api.placeOrder(
      { cart_id: cartId },
      params?.customQuery ?? null,
      params?.customHeaders,
    );

    return data?.placeOrder ?? null;
  },
};
