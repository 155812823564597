import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import applyCouponToCartGql from '../applyCouponToCart.gql';

export const applyCouponCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    couponCode,
    recaptchaToken,
    customQuery = { applyCouponToCart: 'applyCouponToCart' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
      recaptchaToken,
    });

    const getCartData = async (id: string) => {
      Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const { data, errors } = await context.$magento.api.cart(id);
      Logger.debug('[Result]:', { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    const { data, errors } = await context.$magento.api.customMutation(
      { 
        mutation: applyCouponToCartGql, 
        mutationVariables: {
          input: {
            cart_id: currentCart.id,
            coupon_code: couponCode,
            recaptchaToken: recaptchaToken,
          }
        }
      }
    );

    Logger.debug('[Result]:', { data, errors });
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(joinedErrors);
    }

    const cartData = await getCartData(currentCart.id);
    return {
      updatedCart: cartData as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
};
