/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
        id,
        store_code,
        default_title,
        store_name,
        default_display_currency_code,
        timezone,
        locale,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt,
        base_media_url,
        secure_base_media_url,
        copyright,
        secure_base_url,
        google_maps_api_key,
        google_map_zoom,
        facebook_enable,
        facebook_page_id,
        facebook_color_option,
        amblog_post_image_width,
        amblog_post_image_height,
        popup_enable,
        popup_content,
        popup_bg_color,
        prohibited_word,
        prohibited_contain_word
    }
  }
`;

export default StoreConfigQuery;

