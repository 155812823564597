export default `
  query cart($cart_id: String!) {
    cart(cart_id: $cart_id) {
      id
      applied_coupon {
        rule_id
        id
        name
        code
        description
        discount_type
        discount_amount
        from_date
        to_date
        usage_per_customer
        is_used
        status
        is_valid_used
      }
      items {
        uid
        messages {
          type
          text
        }
        prices {
          price {
            value
            currency
          }
        }
        product {
          price {
            regularPrice {
              amount {
                value
                currency
              }
            }
          }
        }
        ... on SimpleCartItem {
          customizable_options {
            customizable_option_uid
            id
            is_required
            label
            sort_order
            type
            values {
              customizable_option_value_uid
              id
              label
              price {
                type
                units
                value
              }
              value
            }
          }
        }
        ... on ConfigurableCartItem {
          customizable_options {
            customizable_option_uid
            id
            is_required
            label
            sort_order
            type
            values {
              customizable_option_value_uid
              id
              label
              price {
                type
                units
                value
              }
              value
            }
          }
        }
      }
    }
  }
`;
