import {
  readonly,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { CouponList } from '~/modules/GraphQL/types';
import type { UseCouponInterface, UseCouponErrors } from '~/composables/useCoupon/useCoupon';
import type { ComposableFunctionArgs } from '../types';
import getCouponListGql from "./getCouponList.gql";
import keptCouponGql from "./keptCoupon.gql";
import { useUiNotification, useUiState, useUser } from '~/composables';

/**
 * See the {@link UseCouponInterface} for a list of methods and values available in this composable.
 */
export function useCoupon(): UseCouponInterface {
  const loading = ref<boolean>(false);
  const error = ref<UseCouponErrors>({
    getCouponList: null,
    keptCoupon: null,
  });
  const {
    app: { context },
  } = useContext();
  const {
    toggleLoginModal,
  } = useUiState();
  const { load: loadUser, user } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();
  async function getCouponList(params: ComposableFunctionArgs<{ 
      limit: number, 
      page: number, 
      searchName: string, 
      type: string, 
      cart_id?: string | null
    }>): Promise<CouponList> {
    
    Logger.debug('useCoupon/getCouponList');
    loading.value = true;
    let result = {
      items: [],
      totalItems: 0,
    };

    try {
      if (!user.value) {
        await loadUser();
      }
      const customer_email = user.value?.email ?? null;
      const { data } = await context.$vsf.$magento.api.customQuery({
        query: getCouponListGql,
        queryVariables: {
          limit: params.limit,
          page: params.page,
          type: params.type,
          searchName: params.searchName,
          customer_email: customer_email,
          cart_id: params?.cart_id
        },
      });
      error.value.getCouponList = null;
      const items = typeof data.coupons[0] !== 'undefined' ? data.coupons[0].items : [];
      const totalItems = typeof data.coupons[0] !== 'undefined' ? data.coupons[0].totalItems : 0;
      result = {
        items: items,
        totalItems: totalItems,
      };
    } catch (err) {
      error.value.getCouponList = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function keptCoupon(params: ComposableFunctionArgs<{ 
    rule_id: number, 
    coupon_id: number,
    limit: number, 
    page: number, 
    searchName: string, 
    type: string, 
  }>) {
    
    Logger.debug('useCoupon/keptCoupon');
    loading.value = true;
    let result = {
      items: [],
      totalItems: 0,
    };

    try {
      if (!context.$vsf.$magento.config.state.getCustomerToken()) {
        sendNotification({
          id: Symbol('kept_coupon'),
          message: context.i18n.t('You must login or register to add coupons to your collection.') as string,
          type: 'warning',
          icon: 'error',
          persist: false,
          title: 'Kept Coupon',
        });
        toggleLoginModal();
        throw new Error('Need to be authenticated to add a coupons to collection');
      }

      if (!user.value) {
        await loadUser();
      }
      const customer_email = user.value?.email ?? null;
      const { data, errors } = await context.$vsf.$magento.api.customMutation(
        { 
          mutation: keptCouponGql, 
          mutationVariables: { 
            rule_id: params.rule_id,
            coupon_id: params.coupon_id,
            limit: params.limit,
            page: params.page,
            type: params.type,
            searchName: params.searchName,
            customer_email: customer_email
          }
        }
      );
      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol('kept_coupon'),
          message: registerError.message,
          persist: false,
          title: 'Kept Coupon',
          type: 'warning',
        }));
        const getCoupons = await getCouponList({
          limit: params.limit,
          page: params.page,
          type: params.type,
          searchName: params.searchName,
        });
        error.value.keptCoupon = null;
        const items = getCoupons.items;
        const totalItems = getCoupons.totalItems;
        result = {
          items: items,
          totalItems: totalItems,
        };
      } else {
        sendNotification({
          id: Symbol('kept_coupon'),
          message: context.i18n.t('You added coupon to your collection.') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Kept Coupon',
        });
        error.value.keptCoupon = null;
        const items = typeof data.keptCoupon[0] !== 'undefined' ? data.keptCoupon[0].items : [];
        const totalItems = typeof data.keptCoupon[0] !== 'undefined' ? data.keptCoupon[0].totalItems : 0;
        result = {
          items: items,
          totalItems: totalItems,
        };
      }
    } catch (err) {
      error.value.keptCoupon = err;
    } finally {
      loading.value = false;
    }
    
    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    getCouponList,
    keptCoupon,
  };
}

export default useCoupon;
export * from './useCoupon';
