import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { Plugin } from '@nuxt/types';
import { useConfigStore } from '~/stores/config';

const plugin : Plugin = (context, inject) => {
  const configStore = useConfigStore();
  Vue.use(VueGoogleMaps, {
    load: {
      key: configStore.$state.storeConfig.google_maps_api_key,
      libraries: "places"
    }
  });
};

export default plugin;
