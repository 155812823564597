import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _783fb464 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _d825760c = () => interopDefault(import('../pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _5672bb02 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _1c489a0a = () => interopDefault(import('../pages/CategoryProducts.gql.ts' /* webpackChunkName: "pages/CategoryProducts.gql" */))
const _39fa5528 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _2e163674 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _6674ef02 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _572a62f6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _4fc10819 = () => interopDefault(import('../modules/checkout/pages/Checkout/TaxInvoice.vue' /* webpackChunkName: "" */))
const _840ecba8 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _39df1301 = () => interopDefault(import('../pages/checkoutCancelRedirect.vue' /* webpackChunkName: "" */))
const _c543dd58 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _0e550998 = () => interopDefault(import('../pages/Coupons.vue' /* webpackChunkName: "pages/Coupons" */))
const _f990b78e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _7f381eb2 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _577b996c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyCoupon.vue' /* webpackChunkName: "" */))
const _90ff8f46 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _e6c7453a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _bc9d31b2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _d89f132e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _d39e0432 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _1a9b7eb3 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0486ac3c = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _48d96887 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _58208eac = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _5bd911a0 = () => interopDefault(import('../pages/FacebookCallback.vue' /* webpackChunkName: "pages/FacebookCallback" */))
const _277afb7e = () => interopDefault(import('../pages/FlashSales.vue' /* webpackChunkName: "pages/FlashSales" */))
const _41bffd63 = () => interopDefault(import('../pages/GoogleCallback.vue' /* webpackChunkName: "" */))
const _1c5d3c66 = () => interopDefault(import('../pages/NewPassword.vue' /* webpackChunkName: "" */))
const _6f166bd8 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _62e31e3e = () => interopDefault(import('../pages/paySnReturnUrl.vue' /* webpackChunkName: "" */))
const _63796625 = () => interopDefault(import('../pages/ShopByBrand.vue' /* webpackChunkName: "" */))
const _99f39888 = () => interopDefault(import('../pages/Tracking.vue' /* webpackChunkName: "" */))
const _3b45146a = () => interopDefault(import('../pages/blog/amBlogPost.gql.ts' /* webpackChunkName: "pages/blog/amBlogPost.gql" */))
const _8766b168 = () => interopDefault(import('../pages/blog/amBlogPostsSearch.gql.ts' /* webpackChunkName: "pages/blog/amBlogPostsSearch.gql" */))
const _0aef0dd6 = () => interopDefault(import('../pages/blog/customAmBlogPostsByCategoryId.gql.ts' /* webpackChunkName: "pages/blog/customAmBlogPostsByCategoryId.gql" */))
const _2f0a0210 = () => interopDefault(import('../pages/blog/Post.vue' /* webpackChunkName: "pages/blog/Post" */))
const _9aaf1b48 = () => interopDefault(import('../pages/catalog/Brand.vue' /* webpackChunkName: "pages/catalog/Brand" */))
const _9c0ef19a = () => interopDefault(import('../pages/catalog/CatalogSearchResult.vue' /* webpackChunkName: "pages/catalog/CatalogSearchResult" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _783fb464,
    name: "home___en"
  }, {
    path: "/th",
    component: _783fb464,
    name: "home___th"
  }, {
    path: "/en/blog.html",
    component: _d825760c,
    props: (route) => ({
      q: route.query.q
    }),
    name: "Blog___en"
  }, {
    path: "/en/cart",
    component: _5672bb02,
    name: "cart___en"
  }, {
    path: "/en/CategoryProducts.gql",
    component: _1c489a0a,
    name: "CategoryProducts.gql___en"
  }, {
    path: "/en/checkout",
    component: _39fa5528,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _2e163674,
      name: "billing___en"
    }, {
      path: "payment",
      component: _6674ef02,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _572a62f6,
      name: "user-account___en"
    }, {
      path: "tax-invoice",
      component: _4fc10819,
      name: "tax-invoice___en"
    }, {
      path: "thank-you",
      component: _840ecba8,
      name: "thank-you___en"
    }]
  }, {
    path: "/en/checkout-cancel-redirect",
    component: _39df1301,
    name: "Checkout Cancel Redirect___en"
  }, {
    path: "/en/checkoutCancelRedirect",
    component: _39df1301,
    name: "checkoutCancelRedirect___en"
  }, {
    path: "/en/Cms",
    component: _c543dd58,
    name: "Cms___en"
  }, {
    path: "/en/Coupons",
    component: _0e550998,
    name: "Coupons___en"
  }, {
    path: "/en/coupons.html",
    component: _0e550998,
    name: "Coupons___en"
  }, {
    path: "/en/customer",
    component: _f990b78e,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _7f381eb2,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-coupon",
      component: _577b996c,
      meta: {"titleLabel":"My coupon"},
      name: "customer-my-coupon___en"
    }, {
      path: "my-newsletter",
      component: _90ff8f46,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _e6c7453a,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _bc9d31b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _d89f132e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _d39e0432,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _1a9b7eb3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _0486ac3c,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _48d96887,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _58208eac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/FacebookCallback",
    component: _5bd911a0,
    name: "FacebookCallback___en"
  }, {
    path: "/en/fbcallback",
    component: _5bd911a0,
    name: "Facebook Callback___en"
  }, {
    path: "/en/FlashSales",
    component: _277afb7e,
    name: "FlashSales___en"
  }, {
    path: "/en/flashsales.html",
    component: _277afb7e,
    meta: {"title":"FlashSales"},
    name: "FlashSales___en"
  }, {
    path: "/en/ggcallback",
    component: _41bffd63,
    name: "Google Callback___en"
  }, {
    path: "/en/GoogleCallback",
    component: _41bffd63,
    name: "GoogleCallback___en"
  }, {
    path: "/en/Home",
    component: _783fb464,
    name: "Home___en"
  }, {
    path: "/en/new-password",
    component: _1c5d3c66,
    alias: "/customer/account/createPassword",
    name: "New Password___en"
  }, {
    path: "/en/NewPassword",
    component: _1c5d3c66,
    name: "NewPassword___en"
  }, {
    path: "/en/Page",
    component: _6f166bd8,
    name: "Page___en"
  }, {
    path: "/en/paysn-return-url",
    component: _62e31e3e,
    name: "Pay Solution Return Url___en"
  }, {
    path: "/en/paySnReturnUrl",
    component: _62e31e3e,
    name: "paySnReturnUrl___en"
  }, {
    path: "/en/shop-by-brand.html",
    component: _63796625,
    name: "Shop by Brand___en"
  }, {
    path: "/en/ShopByBrand",
    component: _63796625,
    name: "ShopByBrand___en"
  }, {
    path: "/en/tracking",
    component: _99f39888,
    meta: {"title":"Tracking"},
    name: "Tracking Status___en"
  }, {
    path: "/en/Tracking",
    component: _99f39888,
    name: "Tracking___en"
  }, {
    path: "/th/blog.html",
    component: _d825760c,
    props: (route) => ({
      q: route.query.q
    }),
    name: "Blog___th"
  }, {
    path: "/th/cart",
    component: _5672bb02,
    name: "cart___th"
  }, {
    path: "/th/CategoryProducts.gql",
    component: _1c489a0a,
    name: "CategoryProducts.gql___th"
  }, {
    path: "/th/checkout",
    component: _39fa5528,
    name: "checkout___th",
    children: [{
      path: "billing",
      component: _2e163674,
      name: "billing___th"
    }, {
      path: "payment",
      component: _6674ef02,
      name: "payment___th"
    }, {
      path: "shipping",
      component: _572a62f6,
      name: "user-account___th"
    }, {
      path: "tax-invoice",
      component: _4fc10819,
      name: "tax-invoice___th"
    }, {
      path: "thank-you",
      component: _840ecba8,
      name: "thank-you___th"
    }]
  }, {
    path: "/th/checkout-cancel-redirect",
    component: _39df1301,
    name: "Checkout Cancel Redirect___th"
  }, {
    path: "/th/checkoutCancelRedirect",
    component: _39df1301,
    name: "checkoutCancelRedirect___th"
  }, {
    path: "/th/Cms",
    component: _c543dd58,
    name: "Cms___th"
  }, {
    path: "/th/Coupons",
    component: _0e550998,
    name: "Coupons___th"
  }, {
    path: "/th/coupons.html",
    component: _0e550998,
    name: "Coupons___th"
  }, {
    path: "/th/customer",
    component: _f990b78e,
    meta: {"titleLabel":"My Account"},
    name: "customer___th",
    children: [{
      path: "addresses-details",
      component: _7f381eb2,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___th"
    }, {
      path: "my-coupon",
      component: _577b996c,
      meta: {"titleLabel":"My coupon"},
      name: "customer-my-coupon___th"
    }, {
      path: "my-newsletter",
      component: _90ff8f46,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___th"
    }, {
      path: "my-profile",
      component: _e6c7453a,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___th"
    }, {
      path: "my-reviews",
      component: _bc9d31b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___th"
    }, {
      path: "my-wishlist",
      component: _d89f132e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___th"
    }, {
      path: "order-history",
      component: _d39e0432,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___th"
    }, {
      path: "addresses-details/create",
      component: _1a9b7eb3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___th"
    }, {
      path: "/th/reset-password",
      component: _0486ac3c,
      alias: "/customer/account/createPassword",
      name: "reset-password___th"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _48d96887,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___th"
    }, {
      path: "order-history/:orderId",
      component: _58208eac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___th"
    }]
  }, {
    path: "/th/FacebookCallback",
    component: _5bd911a0,
    name: "FacebookCallback___th"
  }, {
    path: "/th/fbcallback",
    component: _5bd911a0,
    name: "Facebook Callback___th"
  }, {
    path: "/th/FlashSales",
    component: _277afb7e,
    name: "FlashSales___th"
  }, {
    path: "/th/flashsales.html",
    component: _277afb7e,
    meta: {"title":"FlashSales"},
    name: "FlashSales___th"
  }, {
    path: "/th/ggcallback",
    component: _41bffd63,
    name: "Google Callback___th"
  }, {
    path: "/th/GoogleCallback",
    component: _41bffd63,
    name: "GoogleCallback___th"
  }, {
    path: "/th/Home",
    component: _783fb464,
    name: "Home___th"
  }, {
    path: "/th/new-password",
    component: _1c5d3c66,
    alias: "/customer/account/createPassword",
    name: "New Password___th"
  }, {
    path: "/th/NewPassword",
    component: _1c5d3c66,
    name: "NewPassword___th"
  }, {
    path: "/th/Page",
    component: _6f166bd8,
    name: "Page___th"
  }, {
    path: "/th/paysn-return-url",
    component: _62e31e3e,
    name: "Pay Solution Return Url___th"
  }, {
    path: "/th/paySnReturnUrl",
    component: _62e31e3e,
    name: "paySnReturnUrl___th"
  }, {
    path: "/th/shop-by-brand.html",
    component: _63796625,
    name: "Shop by Brand___th"
  }, {
    path: "/th/ShopByBrand",
    component: _63796625,
    name: "ShopByBrand___th"
  }, {
    path: "/th/tracking",
    component: _99f39888,
    meta: {"title":"Tracking"},
    name: "Tracking Status___th"
  }, {
    path: "/th/Tracking",
    component: _99f39888,
    name: "Tracking___th"
  }, {
    path: "/en/blog/amBlogPost.gql",
    component: _3b45146a,
    name: "blog-amBlogPost.gql___en"
  }, {
    path: "/en/blog/amBlogPostsSearch.gql",
    component: _8766b168,
    name: "blog-amBlogPostsSearch.gql___en"
  }, {
    path: "/en/blog/Blog",
    component: _d825760c,
    name: "blog-Blog___en"
  }, {
    path: "/en/blog/customAmBlogPostsByCategoryId.gql",
    component: _0aef0dd6,
    name: "blog-customAmBlogPostsByCategoryId.gql___en"
  }, {
    path: "/en/blog/Post",
    component: _2f0a0210,
    name: "blog-Post___en"
  }, {
    path: "/en/catalog/Brand",
    component: _9aaf1b48,
    name: "catalog-Brand___en"
  }, {
    path: "/en/catalog/CatalogSearchResult",
    component: _9c0ef19a,
    name: "catalog-CatalogSearchResult___en"
  }, {
    path: "/en/catalogsearch/result",
    component: _9c0ef19a,
    props: (route) => ({
      q: route.query.q
    }),
    name: "CatalogSearch___en"
  }, {
    path: "/th/blog/amBlogPost.gql",
    component: _3b45146a,
    name: "blog-amBlogPost.gql___th"
  }, {
    path: "/th/blog/amBlogPostsSearch.gql",
    component: _8766b168,
    name: "blog-amBlogPostsSearch.gql___th"
  }, {
    path: "/th/blog/Blog",
    component: _d825760c,
    name: "blog-Blog___th"
  }, {
    path: "/th/blog/customAmBlogPostsByCategoryId.gql",
    component: _0aef0dd6,
    name: "blog-customAmBlogPostsByCategoryId.gql___th"
  }, {
    path: "/th/blog/Post",
    component: _2f0a0210,
    name: "blog-Post___th"
  }, {
    path: "/th/catalog/Brand",
    component: _9aaf1b48,
    name: "catalog-Brand___th"
  }, {
    path: "/th/catalog/CatalogSearchResult",
    component: _9c0ef19a,
    name: "catalog-CatalogSearchResult___th"
  }, {
    path: "/th/catalogsearch/result",
    component: _9c0ef19a,
    props: (route) => ({
      q: route.query.q
    }),
    name: "CatalogSearch___th"
  }, {
    path: "/en/blog/:slug+",
    component: _2f0a0210,
    name: "Post___en"
  }, {
    path: "/en/brand/:slug+",
    component: _9aaf1b48,
    name: "Brand___en"
  }, {
    path: "/th/blog/:slug+",
    component: _2f0a0210,
    name: "Post___th"
  }, {
    path: "/th/brand/:slug+",
    component: _9aaf1b48,
    name: "Brand___th"
  }, {
    path: "/en/:slug+",
    component: _6f166bd8,
    name: "page___en"
  }, {
    path: "/th/:slug+",
    component: _6f166bd8,
    name: "page___th"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
