















































































import { SfBadge, SfButton, SfHeader, SfOverlay } from "@storefront-ui/vue";

import {
	computed,
	defineComponent,
	onMounted,
	ref,
	useContext,
	useFetch,
	useRouter,
} from "@nuxtjs/composition-api";
import SvgImage from "~/components/General/SvgImage.vue";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import HeaderLogo from "~/components/HeaderLogo.vue";
import { useConfig, useUiHelpers, useUiState } from "~/composables";
import type { AvailableStores } from "~/composables";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import { useTopBar } from "./TopBar/useTopBar";

const MobileCategorySidebar = () =>
	import(
		"~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue"
	);

import axios from "axios";
import vClickOutside from "v-click-outside";
import Vue from "vue";
Vue.use(vClickOutside);

export default defineComponent({
	components: {
		HeaderNavigation,
		SfHeader,
		SfOverlay,
		HeaderLogo,
		SvgImage,
		MobileCategorySidebar,
		SfButton,
		SfBadge,
		CurrencySelector: () => import("~/components/CurrencySelector.vue"),
		StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
		SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
	},
	setup() {
		const router = useRouter();
		const { app } = useContext();
		const {
			toggleCartSidebar,
			toggleWishlistSidebar,
			toggleLoginModal,
			toggleMobileMenu,
		} = useUiState();
		const { setTermForUrl, getCatLink } = useUiHelpers();
		const { isAuthenticated, load, user } = useUser();
		const { loadTotalQty: loadCartTotalQty, cart } = useCart();
		const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
		const { categories: categoryList, load: categoriesListLoad } =
			useCategory();

		const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

		const isSearchOpen = ref(false);
		const isShowSearchInput = ref(false);
		const productSearchResults = ref<ProductInterface[] | null>(null);

		const wishlistStore = useWishlistStore();
		const wishlistItemsQty = computed(
			() => wishlistStore.wishlist?.items_count ?? 0,
		);

		const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
		const accountIcon = computed(() =>
			isAuthenticated.value ? "profile_fill" : "profile",
		);
		const categoryTree = ref<CategoryTree[]>([]);
		const brandTree = ref<CategoryTree[]>([]);

		const menuToggle = ref<boolean>(false);
		const menuToggleMobile = ref<boolean>(false);
		const { config } = useConfig();
		const isLoaded = ref(false);
		const stores = ref(<AvailableStores>[]);

		const handleAccountClick = async () => {
			if (isAuthenticated.value) {
				await router.push(app.localeRoute({ name: "customer-my-profile" }));
			} else {
				toggleLoginModal();
			}
		};

		const handleMenuToggle = (e: any) => {
			if (e.target.checked === true) {
				menuToggle.value = true;
			} else {
				menuToggle.value = false;
			}
		};

		useFetch(async () => {
			await categoriesListLoad({ pageSize: 20 });

			const cateTree = categoryList.value?.[0]?.children.filter(
				(category) => category.include_in_menu,
			);

			const baseUrl = config.value.secure_base_url;
			const rs = await axios.get(
				`${baseUrl}rest/V1/utech-shopbybrand/shopbybrandlist`,
				{
					headers: {
						Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
					},
				},
			);

			const categories = useCategoryStore();
			if (categories.categories === null) {
				await categories.load();
			}

			brandTree.value = rs.data[0];

			categoryTree.value = cateTree.concat(rs.data);
			const availableStores = await app.$vsf.$magento.api.availableStores({
				availableStores: "availableStores",
			});
			stores.value = availableStores.data.availableStores
				? availableStores.data.availableStores
				: [];
		});
		onMounted(async () => {
			isLoaded.value = true;
			if (app.$device.isDesktop) {
				await loadCartTotalQty();
				// eslint-disable-next-line promise/catch-or-return
				await loadWishlistItemsCount();
				await load();
			}
		});

		const onClickOutside = () => {
			if (menuToggle.value === true) {
				menuToggle.value = false;
			}
		};

		const onClickOutsideMobile = () => {
			if (menuToggleMobile.value === true) {
				menuToggleMobile.value = false;
			}
		};

		const closeMenuMobile = () => {
			menuToggleMobile.value = false;
			toggleMobileMenu(false);
		};

		const loadCategoryMenu = async (e: any) => {
			if (e.target.checked === true) {
				menuToggleMobile.value = true;
				toggleMobileMenu(true);
			} else {
				menuToggleMobile.value = false;
				toggleMobileMenu(false);
			}
		};

		const showSearchInput = () => {
			if (isShowSearchInput.value === true) {
				isShowSearchInput.value = false;
			} else {
				isShowSearchInput.value = true;
			}
		};

		const onClickOutsideShowSearchInput = () => {
			if (isShowSearchInput.value === true) {
				isShowSearchInput.value = false;
			}
		};

		const goToCart = async () => {
			await router.push(app.localeRoute({ name: "cart" }));
		};

		const handleClickToCategoryPage = ($event) => {
			if ($event) {
				menuToggle.value = false;
			}
		};

		return {
			accountIcon,
			cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
			categoryTree,
			brandTree,
			getCatLink,
			handleAccountClick,
			isAuthenticated,
			user,
			isSearchOpen,
			productSearchResults,
			setTermForUrl,
			toggleCartSidebar,
			toggleWishlistSidebar,
			wishlistHasProducts,
			wishlistItemsQty,
			hasCurrencyToSelect,
			hasStoresToSelect,
			handleMenuToggle,
			menuToggle,
			onClickOutside,
			menuToggleMobile,
			onClickOutsideMobile,
			loadCategoryMenu,
			closeMenuMobile,
			showSearchInput,
			isShowSearchInput,
			onClickOutsideShowSearchInput,
			goToCart,
			isLoaded,
			stores,
			handleClickToCategoryPage,
		};
	},
});
