export default `
  mutation applyCouponToCartGql($input: ApplyCouponToCartInput!) {
    customApplyCouponToCart(input: $input) {
      cart {
        id
        prices {
          discounts {
              label
              amount {
                  value
              }
          }
        }
      }
    }
  }
`;
