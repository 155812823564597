import { VsfContext } from "~/composables/context";
import { Logger } from "~/helpers/logger";
import { Cart, CartItemInput, AddSimpleProductsToCartInput, AddConfigurableProductsToCartInput } from "~/modules/GraphQL/types";
import { CustomQuery, CustomHeaders } from "~/types/core";
import { useUiState } from "~/composables";
import addConfigurableProductsToCartGql from "./addConfigurableProductsToCart.gql";
import { getItem, mergeItem, removeItem as removeItemStorage } from "~/helpers/asyncLocalStorage";

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (context: VsfContext, { product, quantity, currentCart, productConfiguration, customQuery, customHeaders }) => {
    Logger.debug("[Magento]: Add item to cart", {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();
    const cart_id = apiState.getCartId();
    const { customOptionValue } = useUiState();
    
    if (await getItem("oldCart")) {
      removeItemStorage("oldCart");
    }

    const getCartData = async (id: string) => {
      Logger.debug("[Magento Storefront]: useCart.load.getCartData ID->", id);

      const { data, errors } = await context.$magento.api.cart(id);
      Logger.debug("[Result]:", { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    let result = [],
      i = 0;
    customOptionValue.value.forEach((value, index) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          result[i] = {
            id: index,
            value_string: val,
          };
          i++;
        });
      } else {
        result[i] = {
          id: index,
          value_string: value,
        };
        i++;
      }
    });

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case "SimpleProduct":
        if (productConfiguration) {
          const configurableCartInput: AddConfigurableProductsToCartInput = {
            cart_id,
            cart_items: [
              {
                data: {
                  quantity: quantity,
                  sku: product.sku,
                  rule_id: customQuery.rule_id,
                },
                customizable_options: result,
                parent_sku: product.sku,
              },
            ],
          };

          const configurableProduct = await context.$magento.api.customMutation({
            mutation: addConfigurableProductsToCartGql,
            mutationVariables: { input: configurableCartInput },
          });

          Logger.debug("[Result]:", { data: configurableProduct.data });

          const cartData = await getCartData(cart_id);
          // eslint-disable-next-line consistent-return
          return {
            data: cartData as unknown as Cart,
            errors: configurableProduct.errors,
          };
        } else {
          const simpleCartInput: AddSimpleProductsToCartInput = {
            cart_id,
            cart_items: [
              {
                data: { quantity: quantity, sku: product.sku },
                customizable_options: result,
              },
            ],
          };

          const simpleProduct = await context.$magento.api.addSimpleProductsToCart(
            simpleCartInput,
            customQuery as CustomQuery,
            customHeaders as CustomHeaders
          );

          Logger.debug("[Result]:", { data: simpleProduct.data });

          // eslint-disable-next-line consistent-return
          return {
            data: simpleProduct.data.addSimpleProductsToCart?.cart as unknown as Cart,
            errors: simpleProduct.errors,
          };
        }
      case "ConfigurableProduct":
        const selectedOptions = Object.values(productConfiguration as object);

        if (typeof productConfiguration["isPromoItems"] !== "undefined") {
          const configurableCartInput: AddConfigurableProductsToCartInput = {
            cart_id,
            cart_items: [
              {
                data: {
                  quantity: quantity,
                  sku: customQuery.sku,
                  selected_promo_options: productConfiguration["option"],
                  rule_id: customQuery.rule_id,
                },
                customizable_options: result,
                parent_sku: product.sku,
              },
            ],
          };

          const configurableProduct = await context.$magento.api.customMutation({
            mutation: addConfigurableProductsToCartGql,
            mutationVariables: { input: configurableCartInput },
          });

          Logger.debug("[Result]:", { data: configurableProduct.data });

          const cartData = await getCartData(cart_id);
          // eslint-disable-next-line consistent-return
          return {
            data: cartData as unknown as Cart,
            errors: configurableProduct.errors,
          };
        } else {
          const configurableCartInput: AddConfigurableProductsToCartInput = {
            cart_id,
            cart_items: [
              {
                data: {
                  quantity: quantity,
                  sku: product.configurable_product_options_selection.variant?.sku,
                  selected_options: selectedOptions,
                },
                customizable_options: result,
                parent_sku: product.sku,
              },
            ],
          };

          const configurableProduct = await context.$magento.api.addConfigurableProductsToCart(
            configurableCartInput,
            customQuery as CustomQuery,
            customHeaders as CustomHeaders
          );

          Logger.debug("[Result]:", { data: configurableProduct.data });

          // eslint-disable-next-line consistent-return
          return {
            data: configurableProduct.data.addConfigurableProductsToCart?.cart as unknown as Cart,
            errors: configurableProduct.errors,
          };
        }
      case "BundleProduct":
        const createEnteredOptions = () =>
          // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions(),
            },
          ],
        };

        const bundleProduct = await context.$magento.api.addProductsToCart(
          bundleCartInput,
          customQuery as CustomQuery,
          customHeaders as CustomHeaders
        );

        Logger.debug("[Result]:", { data: bundleProduct });

        // eslint-disable-next-line consistent-return
        return {
          data: bundleProduct.data.addProductsToCart?.cart as unknown as Cart,
          errors: bundleProduct.data.addProductsToCart?.user_errors,
        };
      case "DownloadableProduct":
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        const downloadableProduct = await context.$magento.api.addProductsToCart(
          downloadableCartInput,
          customQuery as CustomQuery,
          customHeaders as CustomHeaders
        );

        Logger.debug("[Result DownloadableProduct]:", { data: downloadableProduct });

        // eslint-disable-next-line consistent-return
        return {
          data: downloadableProduct.data.addProductsToCart?.cart as unknown as Cart,
          errors: downloadableProduct.data.addProductsToCart?.user_errors,
        };
      case "VirtualProduct":
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };
        const virtualProduct = await context.$magento.api.addProductsToCart(
          virtualCartInput,
          customQuery as CustomQuery,
          customHeaders as CustomHeaders
        );

        Logger.debug("[Result VirtualProduct]:", { data: virtualProduct });

        // eslint-disable-next-line consistent-return
        return {
          data: virtualProduct.data.addProductsToCart?.cart as unknown as Cart,
          errors: virtualProduct.data.addProductsToCart?.user_errors,
        };
      case "GroupedProduct":
        const groupedCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: product.items.map((item) => ({
            quantity,
            sku: item.product.sku,
          })),
        };

        const groupedProduct = await context.$magento.api.addProductsToCart(groupedCartInput, customQuery as CustomQuery);

        Logger.debug("[Result GroupedProduct]:", { data: groupedProduct });

        // eslint-disable-next-line consistent-return
        return {
          data: groupedProduct.data.addProductsToCart?.cart as unknown as Cart,
          errors: groupedProduct.data.addProductsToCart?.user_errors,
        };
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
