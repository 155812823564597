import {
  readonly,
  ref,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { SidebarMenuCms } from '~/modules/GraphQL/types';
import type { UseSidebarMenuCmsInterface, UseSidebarMenuCmsErrors } from '~/composables/useSidebarMenuCms/useSidebarMenuCms';
import type { ComposableFunctionArgs } from '../types';
import axios from "axios";
import { useConfig } from '~/composables';

/**
 * See the {@link UseSidebarMenuCmsInterface} for a list of methods and values available in this composable.
 */
export function useSidebarMenuCms(): UseSidebarMenuCmsInterface {
  const loading = ref<boolean>(false);
  const error = ref<UseSidebarMenuCmsErrors>({
    getByIdentifier: null,
  });
  const { config } = useConfig();
  async function getByIdentifier(params: ComposableFunctionArgs<{ identifier: string }>): Promise<SidebarMenuCms[]> {
    Logger.debug('useSidebarMenuCms/getByIdentifier');
    loading.value = true;
    let result = [];

    const baseUrl = config.value.secure_base_url;

    try {
      const response = await axios.get(
        baseUrl + "rest/V1/menus/" + params.identifier + "/nodes",
        {
          headers: {
            Authorization: "Bearer " + process.env.MG_ACCESS_TOKEN,
          },
        }
      );
      error.value.getByIdentifier = null;
      result = response.data;
    } catch (err) {
      error.value.getByIdentifier = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    getByIdentifier,
  };
}

export default useSidebarMenuCms;
export * from './useSidebarMenuCms';
