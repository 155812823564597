export default `
query getCouponList($limit: Int, $page: Int, $type: String, $customer_email: String, $searchName: String,$cart_id: String) {
  coupons(limit: $limit, page: $page, type: $type, customer_email: $customer_email, searchName: $searchName,cart_id: $cart_id) {
    items {
      rule_id
      id
      name
      code
      description
      discount_type
      discount_amount
      from_date
      to_date
      usage_per_customer
      is_used
      status
      is_valid_used
    }
    totalItems
  }
}
`;
