















import { SfImage } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import IconLogo from '~/components/General/IconLogo.vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage, IconLogo },
  setup() {
    const { config } = useConfig();

    const logoSrc = computed(() => {
      const baseMediaUrl = config.value.secure_base_media_url;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });

    const headerLogoSrc = config.value.header_logo_src ? config.value.header_logo_src : '' || '';

    const logoWidth = computed(
      () => config.value.logo_width || '160',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '30',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || '',
    );

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
      headerLogoSrc,
    };
  },
});
