import {
  readonly,
  ref,
} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UsePromoInterface, UsePromoErrors } from '../usePromo';
import type { ComposableFunctionArgs } from '~/composables/types';
import axios from "axios";
import { useConfig } from '~/composables';

/**
 * See the {@link UsePromoInterface} for a list of methods and values available in this composable.
 */
export function usePromo(): UsePromoInterface {
  const loading = ref<boolean>(false);
  const error = ref<UsePromoErrors>({
    getPromoByCartId: null,
  });
  const { config } = useConfig();
  async function getPromoByCartId(params: ComposableFunctionArgs<{ cart_id: string }>): Promise<string> {
    Logger.debug('usePromo/getPromoByCartId');
    loading.value = true;
    let result = '';

    const baseUrl = config.value.secure_base_url;

    try {
      const response = await axios.get(
        baseUrl + "rest/V1/utech/promo/jsondata",
        {
          headers: {
            Authorization: "Bearer " + process.env.MG_ACCESS_TOKEN,
          },
          params: { 
            cart_id: params.cart_id 
          },
        }
      );
      error.value.getPromoByCartId = null;
      result = response.data;
    } catch (err) {
      error.value.getPromoByCartId = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    getPromoByCartId,
  };
}

export default usePromo;
export * from './usePromo';
