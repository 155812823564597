import locale781324cd from '../../lang/th.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"th","numberFormats":{"th":{"currency":{"style":"currency","currency":"THB","currencyDisplay":"symbol"}},"en":{"currency":{"style":"currency","currency":"THB","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"th","file":"th.js","iso":"th","defaultCurrency":"THB"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"THB"}],
  defaultLocale: "th",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/vue/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "https://next.ut.in.th",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "TH",
  normalizedLocales: [{"code":"th","file":"th.js","iso":"th","defaultCurrency":"THB"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"THB"}],
  localeCodes: ["th","en"],
}

export const localeMessages = {
  'th.js': () => Promise.resolve(locale781324cd),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
