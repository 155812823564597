export default `
  mutation keptCouponGql($rule_id: Int!, $coupon_id: Int!, $customer_email: String!, $limit: Int, $page: Int, $type: String, $searchName: String) {
    keptCoupon(rule_id: $rule_id, coupon_id: $coupon_id, customer_email: $customer_email, limit: $limit, page: $page, type: $type, searchName: $searchName) {
      items {
        rule_id
        id
        name
        code
        description
        discount_type
        discount_amount
        from_date
        to_date
        usage_per_customer
        is_used
        status
      }
      totalItems
    }
  }
`;
