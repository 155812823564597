import {
  readonly,
  ref,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { CustomForm } from '~/modules/GraphQL/types';
import type { UseCustomFormInterface, UseCustomFormErrors } from '~/composables/useCustomForm/useCustomForm';
import type { ComposableFunctionArgs } from '../types';
import axios from "axios";
import { useConfig } from '~/composables';

/**
 * See the {@link UseCustomFormInterface} for a list of methods and values available in this composable.
 */
export function useCustomForm(): UseCustomFormInterface {
  const loading = ref<boolean>(false);
  const error = ref<UseCustomFormErrors>({
    getCustomFormById: null,
    postCustomForm: null,
  });
  const { config } = useConfig();
  async function getCustomFormById(params: ComposableFunctionArgs<{ formId: string }>): Promise<CustomForm> {
    Logger.debug('useCustomForm/getCustomFormById');
    loading.value = true;

    const baseUrl = config.value.secure_base_url;

    try {
      const response = await axios.get(
        baseUrl + "rest/V1/amasty_customform/form",
        {
          headers: {
            Authorization: "Bearer " + process.env.MG_ACCESS_TOKEN,
          },
          params: { 
            formId: params.formId 
          }
        }
      );
      error.value.getCustomFormById = null;
      return response.data;
    } catch (err) {
      error.value.getCustomFormById = err;
    } finally {
      loading.value = false;
    }
  }

  async function postCustomForm(params: ComposableFunctionArgs<{ submitAnswer: any }>): Promise<any> {
    Logger.debug('useCustomForm/postCustomForm');
    loading.value = true;

    const baseUrl = config.value.secure_base_url;

    try {
      const response = await axios.post(
        baseUrl + "rest/V1/amasty_customform/answer",
        params.submitAnswer,
        {
          headers: {
            Authorization: "Bearer " + process.env.MG_ACCESS_TOKEN,
          },
        }
      );
      error.value.postCustomForm = null;
      return response.data;
    } catch (err) {
      error.value.postCustomForm = err;
    } finally {
      loading.value = false;
    }
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    getCustomFormById,
    postCustomForm,
  };
}

export default useCustomForm;
export * from './useCustomForm';
