const fragmentPriceRangeFields = `
  fragment PriceRangeFields on PriceRange {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
`;

export default `
  query getFacetData(
    $search: String = "", 
    $filter: ProductAttributeFilterInput, 
    $pageSize: Int = 24, 
    $currentPage: Int = 1, 
    $sort: ProductAttributeSortInput,
    $configurations: [ID!]
  ) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
          count
          level
          image
        }
      }
      items {
        __typename
        id
        uid
        sku
        name
        short_description {
          html
        }
        product_custom_label
        product_custom_label_data {
          logo_list
          logo_detail
          title
          description
          top_cms_block_id
        }
        product_image_label
        product_frame
        brand_label
        stock_status
        only_x_left_in_stock
        flash_sale_price
        is_currently_flash_sale
        thumbnail {
          url
          url_webp
          position
          disabled
          label
        }
        small_image {
          url
          url_webp
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        product_price_type_show
        custom_options

        ... on ConfigurableProduct {
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
          configurable_options {
            id
            uid
            attribute_id
            attribute_uid
            attribute_code
            position
            label
            swatch_input_type
            values {
              id
              uid
              label
              swatch_data {
                value
              }
            }
          }

          configurable_product_options_selection(configurableOptionValueUids: $configurations) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            media_gallery {
              url_webp
              disabled
              label
              position
              url
            }
            variant {
              uid
              sku
              name
              price_range {
                ...PriceRangeFields
              }
              stock_status
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
  ${fragmentPriceRangeFields}
`;
