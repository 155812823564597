// plugins/vue-moment.js

import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

// Set default timezone to Asia/Bangkok
moment.tz.setDefault('Asia/Bangkok')

Vue.use(VueMoment, { moment })
