import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface, CartQuery } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs, useUiNotification } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { useApi } from '~/composables/useApi';
import getCartQueryGql from '~/modules/checkout/composables/useCart/getCartQuery.gql';
import usePromo from '~/modules/checkout/composables/usePromo';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
  CART,
  CART_ITEM,
  PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app: { i18n, $vsf } } = useContext();
  const context = $vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const { query } = useApi();
  const cart = computed(() => cartStore.cart as CART);
  const promo = computed(() => cartStore.promo);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { send: sendNotification, notifications } = useUiNotification();
  const { getPromoByCartId } = usePromo();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = async (newCart: CART) => {
    Logger.debug('useCart.setCart', newCart);

    if (newCart && newCart.id) {
      const moreDataCart = await getMoreCartData(newCart);
      cartStore.$patch((state) => {
        state.cart = moreDataCart.moreDataCart;
        state.promo = moreDataCart.promo;
      });
    } else {
      cartStore.$patch((state) => {
        state.cart = newCart;
      });
    }
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const promo = ref(null);
      let loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      if (loadedCart) {
        const moreDataCart = await getMoreCartData(loadedCart);
        loadedCart = moreDataCart.moreDataCart;
        promo.value = moreDataCart.promo;
      }
      cartStore.$patch((state) => {
        state.cart = loadedCart;
        state.promo = promo;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const getMoreCartData = async (loadedCart: Cart) => {
    const promo = ref(null);
    const { data } = await getCustomizableOptions(loadedCart.id);
    loadedCart.items.map((item, index) => {
      item['customizable_options'] = data.cart?.items[index]['customizable_options'];
      item['messages'] = data.cart?.items[index]['messages'];
      item['item_price'] = data.cart?.items[index]['prices']['price']['value'];
      item['regular_price'] = data.cart?.items[index]['product']['price']['regularPrice']['amount']['value'];
      loadedCart.items[index] = item;
    });
    loadedCart['applied_coupon'] = data.cart['applied_coupon'];
    const _promo = await getPromoByCartId({ cart_id: loadedCart.id });
    promo.value = JSON.parse(_promo);
    return {
      moreDataCart: loadedCart,
      promo: promo,
    }
  }

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });
      const moreDataCart = await getMoreCartData(loadedCart);
      cartStore.$patch((state) => {
        state.cart = moreDataCart.moreDataCart;
        state.promo = moreDataCart.promo;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const getCustomizableOptions = async (cart_id: string) => {
    return query<CartQuery>(getCartQueryGql, { cart_id: cart_id });
  }

  const addItem = async ({
    product, quantity, productConfiguration, customQuery, customHeaders,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const { data: updatedCart, errors } = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
      });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: i18n.t(registerError.message) as string,
          persist: false,
          title: 'Registration error',
          type: 'warning',
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      } else {
        const moreDataCart = await getMoreCartData(updatedCart);
        cartStore.$patch((state) => {
          state.cart = moreDataCart.moreDataCart;
          state.promo = moreDataCart.promo;
        });

        sendNotification({
          id: Symbol('product_added'),
          message: i18n.t('You added {0} to your shopping cart.', {
            0: product.name,
          }) as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Product added',
        });
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
      sendNotification({
        id: Symbol('product_added'),
        message: i18n.t(String(err)) as string,
        type: 'warning',
        icon: 'error',
        persist: false,
        title: 'Product added',
      });
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });
      const moreDataCart = await getMoreCartData(updatedCart);
      cartStore.$patch((state) => {
        state.cart = moreDataCart.moreDataCart;
        state.promo = moreDataCart.promo;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const { data: updatedCart, errors } = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });
        if (errors) {
          const joinedErrors = errors.map((e) => e.message).join(',');
          Logger.error(joinedErrors);
          errors.forEach((registerError, i) => sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: registerError.message,
            persist: false,
            title: 'Registration error',
            type: 'warning',
          }));
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          throw new Error(joinedErrors);
        } else {
          const moreDataCart = await getMoreCartData(updatedCart);
          cartStore.$patch((state) => {
            state.cart = moreDataCart.moreDataCart;
            state.promo = moreDataCart.promo;
          });

          sendNotification({
            id: Symbol('product_updated'),
            message: i18n.t('You updated qty {0} your shopping cart.', {
              0: product.product.name,
            }) as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Product updated',
          });
        }
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, recaptchaToken = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
      recaptchaToken,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);


    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      const moreDataCart = await getMoreCartData(updatedCart);
      cartStore.$patch((state) => {
        state.cart = moreDataCart.moreDataCart;
        state.promo = moreDataCart.promo;
      });
    }
  };

  const applyCoupon = async ({ couponCode, recaptchaToken = null, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      const res = await handleCoupon(couponCode, recaptchaToken, customQuery);
      sendNotification({
        id: Symbol('cart_apply_coupon'),
        message: i18n.t('apply your coupon successfully') as string,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Cart apply coupon',
      });




      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      //   Logger.error('useCart/applyCoupon', err);
      sendNotification({
        id: Symbol('coupon_cannot_be_used.'),
        message: i18n.t('Coupon cannot be used') as string,
        type: 'warning',
        icon: 'check',
        persist: false,
        title: 'Coupon cannot be used',
      });
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);

      if (customQuery?.isChange != "1") {
        sendNotification({
          id: Symbol('cart_cancel_coupon'),
          message: i18n.t('cancel your coupon successfully') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Cart cancel coupon',
        });
      }
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
    promo,
  };
}

export default useCart;
export * from './useCart';
