import {
  readonly,
  Ref,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import {
  UseForgotPasswordResults,
  UseForgotPasswordErrors,
  UseForgotPasswordResetParams,
  UseForgotPasswordSetNewParams,
  UseForgotPasswordInterface,
} from '~/modules/customer/composables/useForgotPassword/useForgotPassword';
import requestPasswordResetEmailGql from './requestPasswordResetEmail.gql';
import resetPasswordGql from './resetPassword.gql';

/**
 * Allows to request a password reset email and to set a new password to a user.
 *
 * Se the {@link UseForgotPasswordInterface} for a list of methods and values available in this composable.
 */
export function useForgotPassword(): UseForgotPasswordInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const result: Ref<UseForgotPasswordResults> = ref({
    resetPasswordResult: null,
    setNewPasswordResult: null,
  });
  const error: Ref<UseForgotPasswordErrors> = ref({
    request: null,
    setNew: null,
    message: null,
  });

  // eslint-disable-next-line @typescript-eslint/require-await,consistent-return
  const resetPassword = async (resetPasswordParams: ComposableFunctionArgs<UseForgotPasswordResetParams>) => {
    Logger.debug('useForgotPassword/request', resetPasswordParams.email);

    try {
      loading.value = true;
      Logger.debug('[Magento]: Reset user password', resetPasswordParams);
      // eslint-disable-next-line max-len
      const { data, errors } = await app.$vsf.$magento.api.customMutation(
        { 
          mutation: requestPasswordResetEmailGql, 
          mutationVariables: { 
            email: resetPasswordParams.email, 
            recaptchaToken: resetPasswordParams.recaptchaToken 
          }
        }
      );
      Logger.debug('[Result]:', { data });
      error.value.request = null;
      result.value.resetPasswordResult = data['customRequestPasswordResetEmail'] !== null ? data['customRequestPasswordResetEmail']['success'] : false;
      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        error.value.message = joinedErrors;
      } else {
        error.value.message = null;
      }
    } catch (err) {
      error.value.request = err;
      Logger.error('useForgotPassword/request', err);
    } finally {
      loading.value = false;
    }
  };

  const setNewPassword = async (setNewPasswordParams: ComposableFunctionArgs<UseForgotPasswordSetNewParams>) => {
    Logger.debug('useForgotPassword/setNew', setNewPasswordParams);

    try {
      loading.value = true;
      const { data, errors } = await app.$vsf.$magento.api.customMutation(
        { 
          mutation: resetPasswordGql, 
          mutationVariables: { 
            id: setNewPasswordParams.id,
            newPassword: setNewPasswordParams.newPassword,
            resetPasswordToken: setNewPasswordParams.tokenValue,
            recaptchaToken: setNewPasswordParams.recaptchaToken,
          }
        }
      );

      Logger.debug('[Result]:', { data });
      result.value.setNewPasswordResult = data['customResetPassword'] !== null ? data['customResetPassword']['success'] : false;
      error.value.setNew = null;
      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        error.value.message = joinedErrors;
      } else {
        error.value.message = null;
        if (result.value.setNewPasswordResult) {
          error.value.message = 'You updated your password.';
        } 
      }
    } catch (err) {
      error.value.setNew = err;
      Logger.error('useForgotPassword/setNew', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    request: resetPassword,
    setNew: setNewPassword,
    loading: readonly(loading),
    result: readonly(result),
    error: readonly(error),
  };
}

export * from './useForgotPassword';
export default useForgotPassword;
