import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { useApi } from '~/composables/useApi';
import { CmsPageQuery } from '~/modules/GraphQL/types';
import getCmsPageQueryGql from '~/composables/useContent/commands/getCmsPageQuery.gql';

export const loadContentCommand = {
  execute: async (context: VsfContext, params) => {
    const { query } = useApi();
    Logger.debug('[Magento]: Load CMS Page content', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { data } = await query<CmsPageQuery>(getCmsPageQueryGql, {identifier: params.identifier});

    Logger.debug('[Result]:', { data });

    return data.cmsPage;
  },
};
