












































import {
	defineComponent,
	onMounted,
	ref,
	useContext,
	useRoute,
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import AppHeader from "~/components/AppHeader.vue";
import FacebookChatButtonMobile from "~/components/FacebookChatButtonMobile.vue";
import ScrollToTopBtn from "~/components/ScrollToTopBtn.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import { useUiState } from "~/composables";
import { useConfig } from "~/composables";
import { loadBlocksCommand } from "~/composables/useContent/commands/loadBlocksCommand";
import type { CmsBlock } from "~/modules/GraphQL/types";

export default defineComponent({
	name: "DefaultLayout",

	components: {
		LoadWhenVisible,
		LazyHydrate,
		AppHeader,
		TopBar,
		AppFooter: () =>
			import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
		CartSidebar: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/checkout/components/CartSidebar.vue"
			),
		WishlistSidebar: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
			),
		LoginModal: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
			),
		PopupModal: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/customer/components/PopupModal/PopupModal.vue"
			),
		LoginCheckoutModal: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginCheckoutModal.vue"
			),
		UseCouponModal: () =>
			import(
				/* webpackPrefetch: true */ "~/components/Coupon/UseCouponModal.vue"
			),
		SoldoutCouponModal: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/checkout/pages/Cart/SoldoutCouponModal.vue"
			),
		SummaryPaymentModal: () =>
			import(
				/* webpackPrefetch: true */ "~/modules/customer/components/CheckoutModal/SummaryPaymentModal.vue"
			),
		Notification: () =>
			import(/* webpackPrefetch: true */ "~/components/Notification.vue"),

		IconSprite: () =>
			import(/* webpackPrefetch: true */ "~/components/General/IconSprite.vue"),
		FacebookChatButtonMobile,
		ScrollToTopBtn,
	},

	setup(_, { emit }) {
		const route = useRoute();
		const {
			isCartSidebarOpen,
			isWishlistSidebarOpen,
			isLoginModalOpen,
			toggleLoginModal,
			isPopupModalOpen,
			togglePopupModal,
			isLoginCheckoutModalOpen,
			toggleLoginCheckoutModal,
			isSummaryPaymentModalOpen,
			toggleSummaryPaymentModal,
			isUseCouponModalOpen,
			toggleUseCouponModal,
			isSoldoutCouponModalOpen,
			toggleSoldoutCouponModal,
		} = useUiState();

		const { config } = useConfig();

		const blocks = ref<CmsBlock[]>([]);
		const popupContent = ref("");
		const { app } = useContext();
		const context = app.$vsf;

		const isMobile = ref(false);
		const popup = ref();

		onMounted(async () => {
			if (window.innerWidth < 1024) {
				isMobile.value = true;
			} else {
				isMobile.value = false;
			}

			popup.value = config.value;
			popupContent.value = popup.value.popup_content;
			blocks.value = await loadBlocksCommand.execute(context, {
				identifiers: [popupContent.value],
			});
			const getCookie = async (cookieName: string) => {
				const name = `${cookieName}=`;
				const decodedCookie = decodeURIComponent(document.cookie);
				const cookieArray = decodedCookie.split(";");

				for (let i = 0; i < cookieArray.length; i++) {
					let cookie = cookieArray[i];

					while (cookie.charAt(0) === " ") {
						cookie = cookie.substring(1);
					}
					if (cookie.indexOf(name) === 0) {
						return cookie.substring(name.length, cookie.length);
					}
				}
				return null; // Return null if the cookie is not found
			};
			if (
				popup.value.popup_enable === "1" &&
				!(await getCookie("closeModal"))
			) {
				togglePopupModal(true);
			} else {
				togglePopupModal(false);
			}
		});

		return {
			isCartSidebarOpen,
			isWishlistSidebarOpen,
			isLoginModalOpen,
			toggleLoginModal,
			isPopupModalOpen,
			togglePopupModal,
			isLoginCheckoutModalOpen,
			toggleLoginCheckoutModal,
			isSummaryPaymentModalOpen,
			toggleSummaryPaymentModal,
			isUseCouponModalOpen,
			toggleUseCouponModal,
			isSoldoutCouponModalOpen,
			toggleSoldoutCouponModal,
			route,
			blocks,
			isMobile,
		};
	},
	head: {},
});
