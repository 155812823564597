import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isPopupModalOpen: false,
  isFreeGiftModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isUseCouponModalOpen: false,
  isApplyingCoupon: false,
  isImageModalOpen: false,
  isImageModalIndex: 0,
  isSoldoutCouponModalOpen: false,
  customOptionPirce: 0,
  customOptionValue: [],
  customFormValue: [],
  isLoginCheckoutModalOpen: false,
  isSummaryPaymentModalOpen: false,
  soldoutCoupon: ''
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = (value: boolean) => {
    state.isMobileMenuOpen = value;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu(false);
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu(false);
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu(false);
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const togglePopupModal = (value: boolean) => {
    state.isPopupModalOpen = value;
  };

  const toggleLoginCheckoutModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu(false);
    state.isLoginCheckoutModalOpen = !state.isLoginCheckoutModalOpen;
  };

  const toggleUseCouponModal = (value: boolean = null) => {
    state.isUseCouponModalOpen = value;
  };

  const toggleApplyingCoupon = (value: boolean) => {
    state.isApplyingCoupon = value;
  };

  const toggleSoldoutCouponModal = (value: boolean, code: string = null) => {
    state.isSoldoutCouponModalOpen = value;
    state.soldoutCoupon = code;
  };

  const toggleSummaryPaymentModal = () => {
    // if (state.isMobileMenuOpen) toggleMobileMenu(false);
    state.isSummaryPaymentModalOpen = !state.isSummaryPaymentModalOpen;
  };

  const toggleFreeGiftModal = (value: boolean) => {
    state.isFreeGiftModalOpen = value;
  };

  const onImageModalOpen = (_index) => {
    if (state.isImageModalOpen === false) {
      setTimeout(async () => {
        state.isImageModalOpen = true;
        state.isImageModalIndex = _index;
      }, 100);
    }
  };

  const setCustomFormValue = (identifier: string, formId: string, feildName: string, value: any) => {
    if (feildName == '') {
      state.customFormValue = [];
    } else {
      state.customFormValue[identifier + '-' + formId + '-' + feildName] = value;
    }
  }

  const calCustomOptionPirce = (price: number) => {
    state.customOptionPirce = state.customOptionPirce + price;
  }

  const resetCustomOptionPirce = () => {
    state.customOptionPirce = 0;
  }

  const setCustomOptionValues = (values: any) => {
    state.customOptionValue = values;
  }

  const closeImageModal = () => {
    if (state.isImageModalOpen === true) {
      state.isImageModalOpen = false;
    }
  }

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isUseCouponModalOpen: computed(() => state.isUseCouponModalOpen),
    isApplyingCoupon: computed(() => state.isApplyingCoupon),
    isPopupModalOpen: computed(() => state.isPopupModalOpen),
    isLoginCheckoutModalOpen: computed(() => state.isLoginCheckoutModalOpen),
    isSummaryPaymentModalOpen: computed(() => state.isSummaryPaymentModalOpen),
    isFreeGiftModalOpen: computed(() => state.isFreeGiftModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isImageModalOpen: computed(() => state.isImageModalOpen),
    isSoldoutCouponModalOpen: computed(() => state.isSoldoutCouponModalOpen),
    isImageModalIndex: computed(() => state.isImageModalIndex),
    customOptionPirce: computed(() => state.customOptionPirce),
    customOptionValue: computed(() => state.customOptionValue),
    customFormValue: computed(() => state.customFormValue),
    soldoutCoupon: computed(() => state.soldoutCoupon),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    togglePopupModal,
    toggleLoginCheckoutModal,
    toggleUseCouponModal,
    toggleSummaryPaymentModal,
    toggleFreeGiftModal,
    toggleApplyingCoupon,
    toggleSoldoutCouponModal,
    onImageModalOpen,
    closeImageModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    calCustomOptionPirce,
    resetCustomOptionPirce,
    setCustomOptionValues,
    setCustomFormValue,
  };
}

export default useUiState;
export * from './useUiState';
