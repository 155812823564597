export default `
  query getUpsellProductBySku(
    $filter: ProductAttributeFilterInput
  ) {
    products(filter: $filter) {
      items {
        upsell_products {
          uid
          sku
          name
          stock_status
          only_x_left_in_stock
          rating_summary
          product_frame
          product_custom_label
          product_custom_label_data {
            logo_list
            logo_detail
            title
            description
            top_cms_block_id
          }
          product_price_type_show
          thumbnail {
            url
            url_webp
            position
            disabled
            label
            __typename
          }
          url_key
          url_rewrites {
            url
            __typename
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
                __typename
              }
              regular_price {
                currency
                value
                __typename
              }
              __typename
            }
            minimum_price {
              final_price {
                currency
                value
                __typename
              }
              regular_price {
                currency
                value
                __typename
              }
              __typename
            }
            __typename
          }
          categories {
            uid
            name
            url_suffix
            url_path
            breadcrumbs {
              category_name
              category_url_path
              __typename
            }
            __typename
          }
          review_count
          reviews {
            items {
              average_rating
              ratings_breakdown {
                name
                value
              }
            }
            __typename
          }
          short_description {
            html
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
`;
